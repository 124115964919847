.date {
    font-weight: bold;
    font-size: 2em;
    display: block;
    text-align: center;
}

.weatherselect {
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.widgetlabel {
    font-size: 13px;
    display: block;
    text-align: center;
}

.widgetdata {
    font-weight: bold;
    font-size: 24px;
    display: block;
    text-align: center;
}

.floatingbtncontainer {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1;
}

.noOverflowContainer {
    overflow-y: hidden;
}

.widgetDiv {
    overflow-y: auto;
    height: calc(~"100% - 48px");
    position: absolute; /*Wird für container benötigt*/
    width: 100%;
}

/* Widget general styles */
.widget-menu {
    background-color: #ffffff;
    z-index: 999;
    position: absolute;
    right: 30px;
    top: -1px;
    cursor: pointer;
}

.widget-menu-item:hover {
    background-color: #f6f6f6;
}

.map-widget-map {
    width: 100%;
    height: 90%;
    height: calc(~"100% - 48px"); /*320px; */
}

.map-widget-map-full {
    width: 100%;
    height: 90%;
    height: calc(~"100% - 50px");
}


/* REPORTS */

@media print {

    .ibox-content {
        border: 0 !important;
    }

    .widgetDiv {
        overflow-y: visible;
    }

    .widget-border {
        border: 0 !important;
    }

    .noOverflowContainer {
        overflow: visible !important;
    }

    body {
        overflow: visible !important;
        background-color: #fff !important;
        /*height: 100% !important;*/
    }

    .grid-stack-item-content {
        overflow: auto;
    }

    /*.grid-stack-item-content {*/
    /*position: relative !important;*/
    /*}*/
    .report-page {
        display: block;
        /*page-break-before: always;*/
        page-break-after: always !important;
    }

    table {
        page-break-inside: auto;
    }

    tr {
        page-break-inside: auto;
        page-break-after: auto;
    }

    td {
        page-break-inside: avoid;
        page-break-before: auto;
    }

    td div {
        page-break-inside: avoid;
        page-break-before: auto;
    }

}
